<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <v-card>
      <form @submit.prevent="submitOrder()">
        <v-card-title primary-title>
          <div>
            <div
              v-t="'order.title'"
              class="text-h5"/>
            <span
              v-t="'order.subtitle'"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>
        <v-card-text>
          <payload v-model="order"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.reset'"
            text
            @click="onClickReset"/>
          <v-btn
            v-t="'general.button.submit'"
            color="primary"
            type="submit"
            :disabled="disabled"/>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions} from 'vuex'
  import errorTranslator from '@/app/services/errortranslator'

  import Payload from '@/app/core/components/CodeEditor/Payload'

  export default {
    name: 'OrderSubmission',

    components: {
      Payload
    },

    props: {
      disabled: Boolean,
      initialOrder: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        order: this.initialOrder
      }
    },

    watch: {
      initialOrder (newOrder) {
        this.order = newOrder
      }
    },

    methods: {
      ...mapActions ('request', [
        'fetchData'
      ]),

      /**
       * Submit the Payload order to the backend server.
       */

      submitOrder () {
        this.$emit ('submit', this.order)

        this.fetchData ({
          op: 'order',
          params: {order: this.order},
          cb: data => {
            this.$emit ('success', data.payloadResult)
          },
          cbError: data => {
            const errMsg = data.errorData || errorTranslator (data)

            this.$emit ('error', errMsg)
          }
        })
      },

      onClickReset () {
        this.order = this.initialOrder
        this.$emit ('reset', this.order)
      }
    }
  }
</script>
