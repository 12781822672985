<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw2>
    <v-col cols="12">
      <v-alert
        type="warning"
        :value="!hasClient">
        <strong v-t="'order.warning.text'"/>
        <span v-t="'order.warning.solution'"/>
      </v-alert>
    </v-col>
    <v-col
      id="order"
      cols="12">
      <order-submission
        :disabled="waitingForResponse"
        :initial-order="order"
        @submit="onSubmission"
        @success="onSuccess"
        @error="onError"
        @reset="onReset"/>
    </v-col>
    <v-col cols="12" md="6">
      <previous-order :order="previousOrder"/>
    </v-col>
    <v-col
      id="response"
      cols="12" md="6">
      <order-response
        :waiting="waitingForResponse"
        :response="response"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import goTo from 'vuetify/lib/services/goto'

  import {mapGetters} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import OrderSubmission from './components/OrderSubmission'
  import PreviousOrder from './components/PreviousOrder'
  import OrderResponse from './components/OrderResponse'

  export default {
    name: 'Order',

    components: {
      BaseLayout,
      OrderSubmission,
      PreviousOrder,
      OrderResponse
    },

    data () {
      return {
        waitingForResponse: false,
        order: '',
        previousOrder: '',
        response: ''
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'userName', 'clientId', 'isImpersonated'
      ]),

      hasClient () {
        return this.clientId.clientId || this.clientId.effectiveClientId
      }
    },

    created () {
      this.order = this.getOrderTemplate ()
    },

    methods: {
      /**
       * Return the template for Payload orders. The template contains the
       * Payload version number, the ID of the logged-in user as well as a
       * computed transaction ID.
       */

      getOrderTemplate () {
        const payloadVersionString = 'payload.version: 2.0'
        const timestamp = Date.now ()
        const transactionIdValue = 'GWWeb-' + this.userName + '-' + timestamp
        const transactionIdString = 'transaction.id: ' + transactionIdValue
        const {clientId: client, effectiveClientId: effClient} = this.clientId

        const memberIdPrefix = 'CORE-'
        const memberIdKey = 'core.member.id: '
        const effectiveMemberIdKey = 'effective.member.id: '

        let memberIdString

        if (client === 0) {
          if (effClient !== 0) {
            // no own client -> set core.member.id to impersonated client
            memberIdString = memberIdKey + memberIdPrefix + effClient
          } else {
            // no own and no impersonated client -> do not set core.member.id
            memberIdString = memberIdKey
          }
        } else {
          // set core.member.id to own client and effective.member.id to
          // impersonated client (if any)
          memberIdString = memberIdKey + memberIdPrefix + client + (
            effClient
              ? '\n' + effectiveMemberIdKey + memberIdPrefix + effClient
              : '')
        }

        const requestTypeString = 'request.type: '

        return payloadVersionString + '\n' +
          transactionIdString + '\n' +
          memberIdString + '\n' +
          requestTypeString
      },

      onSubmission (order) {
        this.waitingForResponse = true
        this.previousOrder = order
      },

      onSuccess (response) {
        this.waitingForResponse = false
        this.response = response
        goTo ('#response')
      },

      onError (response) {
        this.waitingForResponse = false
        this.response = response
        this.scrollTo ('#response')
      },

      onReset () {
        this.order = this.getOrderTemplate ()
        this.waitingForResponse = false
        this.scrollTo ('#order')
      },

      scrollTo (id) {
        goTo (id)
      }
    }
  }
</script>
