<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-card>
    <v-card-title>
      <div
        v-t="'order.previous.title'"
        class="text-h5"/>
    </v-card-title>
    <v-card-text>
      <payload
        v-if="order"
        v-model="order"
        readonly/>
      <span
        v-else
        v-t="'order.previous.noOrder'"/>
    </v-card-text>
  </v-card>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import Payload from '@/app/core/components/CodeEditor/Payload'

  export default {
    name: 'PreviousOrder',

    components: {
      Payload
    },

    props: {
      order: {
        type: String,
        required: true
      }
    }
  }
</script>
